<template>
  <app-modal
    ref="modal"
    modal-header-title="Tạo đơn hàng"
    size="xl"

  >
    <template v-slot:header>
      <div class="modal-header clearfix text-left">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          @click="handlerClose()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <span class="title">{{ $t('student_order.order_modal.modal_title') }}</span>
      </div>
    </template>
      <template v-slot:container>
        <div class="bg-white app-table-container">
        <validation-observer
          ref="form_create_entry"
          v-slot="{ passes, valid, validated }"
        >
          <form class="form-horizontal">
            <!-- customer -->
            <div class="row">
              <div class="col-sm-7 mb-3">
                <div class="row align-items-center">
                  <div class="app-align-middle" style="padding-left: 7.5px; min-width: 185.06px">
                    {{ $t('student_order.order_modal.label_select_customer') }}
                    <span class="app-require">＊</span>
                  </div>
                  <div class="flex-grow-1 ml-2">
                    <app-select name="customer"
                        input-style="normal"
                        rules="required"
                        v-model="entry.user_id"
                        :options-data="customerOptions"
                        @change="handleUpdateCustomer($event)"
                      />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectedCustomer">
              <!-- name and phone row -->
              <div class="row">
                <div class="col-sm-6 mb-3">
                  {{ $t('student_order.order_modal.label_customer_name') + (selectedCustomer ? selectedCustomer.customer_name : '') }}
                </div>
                <div class="col-sm-6 mb-3">
                  {{ $t('student_order.order_modal.label_phone') + (selectedCustomer ? selectedCustomer.phone_number : '') }}
                </div>
              </div>
              <!-- email -->
              <div class="row">
                <div class="col-sm-6 mb-3">
                  {{ $t('student_order.order_modal.label_email') + (selectedCustomer ? selectedCustomer.email : '') }}
                </div>
              </div>
              <!-- receiver and class row -->
              <div class="row">
                <div class="col-sm-6 mb-3">
                  {{ $t('student_order.order_modal.label_receiver') + (selectedCustomer ? selectedCustomer.student_name : '') }}
                </div>
                <div class="col-sm-6 mb-3">
                  {{ $t('student_order.order_modal.label_class') + (selectedCustomer ? selectedCustomer.student_class : '') }}
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
          <!-- payment method -->
          <div class="row">
            <div class="col-sm-7 mb-3">
              <div class="row align-items-center">
                <div class="app-align-middle" style="padding-left: 7.5px; min-width: 185.06px"">{{ $t('student_order.order_modal.label_payment_method') }}</div>
                <div class="flex-grow-1 ml-2">
                  <app-select v-if="entry" @change=""
                                  input-style="normal"
                                  v-model="entry.payment"
                                  :options-data="STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAYMENT_METHOD" />
                </div>
              </div>
            </div>
          </div>
          <!-- paid status -->
          <div class="row">
            <div class="col-sm-7 mb-3">
              <div class="row align-items-center">
                <div class="app-align-middle" style="padding-left: 7.5px;">{{ $t('student_order.order_modal.label_paid_status') }}</div>
                <div class="flex-grow-1 ml-2">
                  <app-select v-if="entry" @change=""
                                  input-style="normal"
                                  v-model="entry.paid_status"
                                  :options-data="STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAID_STATUS" />
                </div>
              </div>
            </div>
            <div class="col-sm-5 mb-3 text-right align-self-end">
              <button class="btn btn-success" style="height: 40px !important;"
                @click="handleAddItem"
              >
                {{ $t('student_order.order_modal.button_add_menu') }}
              </button>
            </div>
          </div>
          <!-- table items -->
          <div class="table-responsive">
            <table class="table app-table-list">
              <thead class="thead-light">
              <tr>
                <template v-for="(column, index) in columns">
                  <th :key="index" :class="[column.fitSize ? 'cell-fit-content' : '']">
                    {{ column.label }}
                  </th>
                </template>
              </tr>
              </thead>
              <tbody id="appTableList">
                <tr v-if="!menuStudent || menuStudent.length == 0">
                  <td colspan="100">
                    <span>
                      {{ $t("common.list empty") }}
                    </span>
                  </td>
                </tr>
                <tr v-else :key="item.id" :id="item.id" :data-id="entry.id" v-for="(item, itemIndex) in menuStudent">
                  <!-- id -->
                  <td class="app-align-middle text-center">
                    {{ itemIndex + 1 }}
                  </td>
                  <!-- serve_date -->
                  <td class="app-align-middle text-left">
                    <p
                      :data-original-title="getFullDateString(item.sell_date)"
                      :class="['app-table-p app-cell-tooltip mb-0', checkLineBreak(getFullDateString(item.sell_date))]"
                    >
                      {{ getFullDateString(item.sell_date) }}
                    </p>
                  </td>
                  <!-- menu name -->
                  <td class="app-align-middle text-left">
                    {{ item.name }}
                  </td>
                  <!-- size -->
                  <td class="app-align-middle">
                    <p class=" app-table-p app-cell-tooltip mb-0">
                      <app-select @change="updateSizeMenu($event, itemIndex)"
                                  input-style="normal"
                                  v-model="item.size"
                                  :options-data="STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE" />
                    </p>
                  </td>
                  <!-- price -->
                  <td class="app-align-middle text-right">
                    {{ convertCurrency(item.price) }}
                  </td>
                  <!-- delete action -->
                  <td class="app-align-middle text-center app-p-none">
                    <button
                      @click="handleBtnRemoveClick(itemIndex, item)"
                      class="btn btn-danger"
                    >
                      {{ $t("common_vn.delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- restaurant menus -->
        <transition name="slide">
          <div v-if="isOpenAddMenu" class="menu-view-container">
            <div class="menu-view">
              <div v-if="student_menus" class="row">
                <template v-for="(menu, menuIndex) in student_menus">
                  <div v-if="isNotSelectedMenu(menu.id)" :key="menu.id" class="col-sm-6 col-lg-4">
                    <div class="card" style="overflow: hidden;">
                      <img :src="menu.image.url" class="card-img-top menu-image" alt="...">
                      <div class="card-body border-top border-light">
                        <div class="badge badge-info mb-2">{{ getFullDateString(menu.sell_date) }}</div>
                        <h6 class="card-title font-weight-bold menu-title mb-2" :title="menu.name">{{ menu.name }}</h6>
                        <div class="card-text menu-description" :title="menu.description">{{ menu.description }}</div>
                        <div class="row">
                          <div class="col-sm-4">
                            <app-radio
                              label="S"
                              :key="menu.id"
                              :val="STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_VALUES.S"
                              v-model="menu.selected_size"
                            />
                          </div>
                          <div class="col-sm-4">
                            <app-radio
                              label="M"
                              :key="menu.id"
                              :val="STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_VALUES.M"
                              v-model="menu.selected_size"
                            />
                          </div>
                          <div class="col-sm-4">
                            <app-radio
                              label="L"
                              :key="menu.id"
                              :val="STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_VALUES.L"
                              v-model="menu.selected_size"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="icheck-primary d-inline">
                          <input
                            type="checkbox"
                            :checked="isTempSelected(menu.id)"
                            :id="'checkbox' + menu.id"
                            :key="'checkbox' + menu.id"
                            @click="handleSelectItem(menu.id)"
                          />
                          <label :for="'checkbox' + menu.id">{{ $t('student_order.order_modal.label_select_menu') }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div v-else>Loading...</div>
            </div>
            <div class="menu-footer d-flex align-items-center justify-content-between">
              <button class="btn btn-secondary" @click="isOpenAddMenu = false">{{ $t('common_vn.return') }}</button>
              <button class="btn btn-primary" @click="submitAddNewItem" :disabled="tempSelectedItems.length == 0">{{ $t('common_vn.confirm') }}</button>
            </div>
          </div>
        </transition>

        <!-- footer -->
        <div class="d-flex justify-content-end mt-4">
          <button
            @click="handleCreateOrder"
            type="button"
            :class="['btn btn-primary']"
          >
            {{ $t("common_vn.create") }}
          </button>
        </div>
    </template>
    <template v-slot:footer>

    </template>
  </app-modal>

</template>

<script>
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import {ENDPOINT} from "../../../constants/api";
  import {STUDENT_ORDER_LIST_CONSTANT} from "../../../constants/kyy_student_order_list";
  import {convertCurrency, getFullDateString} from "@utils";
  import AppCheckbox from '../../forms/AppCheckbox';

  export default {
    name: "KyyAppModalStudentOrder",
    components: {
      AppModal,
      "app-basic-table": AppBasicTable,
      "app-checkbox": AppCheckbox,
    },
    props : [
      "endPoint",
    ],
    methods: {
      hide(isUpdateSucces = false) {
        this.$refs.modal.hide();
        this.resetModal()
        if (isUpdateSucces) {
          this.$emit('handleUpdateSuccess');
        }
      },

      show() {
        this.getDataMenus()
        this.$refs.modal.show();
        // Refresh table data
        // this.$refs.basic_table.getEntries();
      },

      handlerClose() {
        this.resetModal()
        // this.$refs.modal.hide()
      },

      handlerSelectOrder(data) {
        this.$emit('selectOrder', data);
      },

      async getDataMenus() {
        await this.$request.get(ENDPOINT.UI_HELPER_KYY_STUDENT_ORDER).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
          } else {
            this.customerList = res.data.customers;
            let customers = _.cloneDeep(res.data.customers);
            customers = customers.map((customer) => {
              return {
                id: customer?.id,
                name: customer?.user_name
              }
            })
            customers.unshift({
              id: null,
              name: 'Chọn một khách hàng',
            });
            this.customerOptions = customers;
          }
        })
        await this.$request.get(ENDPOINT.UI_HELPER_GET_VALID_STUDENT_ORDERS).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
          } else {
            this.student_menus = res.data.data.map((menu) => {
              menu.selected_size = STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_VALUES.S
              return menu
            })
          }
        })
        this.$nextTick(() => {
          $.each($('.app-cell-tooltip'), (index, el) => {
            // Count number of paragraph
            let paras = $(el).text().split('\n');
            let count = 0;
            $.each(paras, (index, value) => {
              count++;
            });

            // Show tooltip
            if ((count >= 3) || (el.offsetWidth < el.scrollWidth)) {
              $(el).tooltip({boundary: 'window'})
            }
          })
        });
      },

      checkLineBreak(note) {
        try {
          if (note) {
            if (note.match(/\n/g)) {
              return 'text-break';
            }
          }
        } catch(err) {
          return ''
        }
      },

      // reset modal
      resetModal() {
        this.entry = {
          user_id: null,
          paid_status: STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAID_STATUS[0].id,
          payment: STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAYMENT_METHOD[0].id
        },
        this.menuStudent = []
        this.original_menuStudent = null
        this.student_menus = null
        this.loading = true
        this.isOpenAddMenu = false
        this.tempSelectedItems = []
      },

      // update menu size order item
      updateSizeMenu(e, index) {
        this.menuStudent[index].size = e
        this.menuStudent[index].price = this.getPriceBySize(e, this.menuStudent[index])
      },

      // handle add items
      handleAddItem() {
        this.isOpenAddMenu = true
      },

      // handle delete item
      handleBtnRemoveClick(index, orderItem) {
        if (this.menuStudent.length == 1) {
          this.__noticeError('Required 1 menu')
          return
        }
        this.menuStudent = this.menuStudent.filter(item => item.id !== orderItem.id)
      },

      handleSelectItem(menuID) {
        if (this.tempSelectedItems.includes(menuID)) {
          this.tempSelectedItems = this.tempSelectedItems.filter(function(id) {
              return id !== menuID
          })
        } else {
          this.tempSelectedItems.push(menuID)
        }
      },

      isTempSelected(menuID) {
        return this.tempSelectedItems.includes(menuID)
      },

      isNotSelectedMenu(menuID) {
        for (const item of this.menuStudent) {
          if (item.id == menuID) {
            return false
          }
        }
        return true
      },

      async submitAddNewItem() {
        if (this.tempSelectedItems.length == 0) {
          return
        }
        let selectedItemArray = []
        for (const item of this.student_menus) {
          if (this.isTempSelected(item.id)) {
            let selectedItem = {}
            selectedItem.id = item.id
            selectedItem.sell_date = item.sell_date
            selectedItem.name = item.name
            selectedItem.size = item.selected_size
            selectedItem.price = this.getPriceBySize(item.selected_size, item)
            selectedItem.price_s = item.price_s
            selectedItem.price_m = item.price_m
            selectedItem.price_l = item.price_l
            selectedItemArray.push(selectedItem)
          }
        }
        this.menuStudent = [...this.menuStudent, ...selectedItemArray]
        this.tempSelectedItems = []
        this.isOpenAddMenu = false
      },

      getPriceBySize(size, menuObject) {
        switch (Number(size)) {
          case STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_VALUES.S:
            return menuObject.price_s
            break;
          case STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_VALUES.M:
            return menuObject.price_m
            break;
          case STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_VALUES.L:
            return menuObject.price_l
            break;
          default:
            return null
            break;
        }
      },

      // handle create order after every changes
      async handleCreateOrder() {
        if (!await this.$refs.form_create_entry.validate()) {
          return;
        }
        let params = {}
        params.paid_status = this.entry.paid_status
        params.payment = this.entry.payment
        params.user_id = this.entry.user_id
        params.menuStudent = _.cloneDeep(this.menuStudent);
        params.menuStudent.map((item) => {
          item.size = STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE_MAPPING[item.size];
          return item;
        });
        console.log(params);
        
        let res = null;
        let msg = "";
        res = await this.$request.post(this.ENDPOINT.KYY_STUDENT_ORDER_CREATE, params);
        msg = this.$t("common.msg update ok")
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        } else {
          this.__noticeSuccess(msg);
          this.hide(true)
        }
      },

      handleUpdateCustomer(e) {
        const customers = this.customerList.filter((customer) => {
          return customer.id == e;
        });
        if (customers.length > 0) {
          this.selectedCustomer = customers[0];
        } else {
          this.selectedCustomer = null;
        }        
      }
    },
    data() {
      return {
        filters: {
          order_number: {
            name: "order_number",
            condition: "like",
            label: this.$t("order_list.order_number")
          },
          customer_name: {
            name: "customer_name",
            condition: "like",
            label: this.$t("order_list.customer_name")
          },
          customer_id: {
            name: "customer_id",
            condition: "equal",
            label: this.$t("receipts.customer_id")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), textAlign: 'text-center'},
          {name: "serve_date", label: this.$t("student_order.serve_date"), textAlign: 'text-center'},
          {name: "menu_name", label: this.$t("student_order.menu_name"), textAlign: 'text-center'},
          {name: "size", label: this.$t("student_order.size"), textAlign: 'text-center'},
          {name: "price", label: this.$t("student_order.price"), textAlign: 'text-center'},
          {name: "delete", label: this.$t("common_vn.action"), textAlign: 'text-center'},
        ],
        entry: {
          user_id: null,
          paid_status: STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAID_STATUS[0].id,
          payment: STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAYMENT_METHOD[0].id
        },
        menuStudent: [],
        original_menuStudent: null,
        student_menus: null,
        loading : true,

        // for menu selector
        isOpenAddMenu: false,
        tempSelectedItems: [],

        selectedCustomer: null,
        customerList: [],
        customerOptions: [],
      }
    },
    mounted() {
    },
  }
</script>
<style>
.menu-view-container {
  width: 100%;
  height: 100%;
  background-color: grey;
}

.menu-view {
  width: 100%; /* Full width */
  height: 100%; /* 70% height of the modal */
  background-color: #f0f0f0; /* Example background color */
  position: absolute; /* Position it absolutely within the modal */
  bottom: 0; /* Align it to the bottom of the modal */
  left: 0;
  right: 0;
  overflow-y: auto; /* If content overflows, add a scrollbar */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.menu-view .row {
  padding: 10px 10px 0 10px;
  overflow: scroll;
  height: calc(100% - 60px);
}

.menu-footer {
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.menu-image {
  aspect-ratio: 4/3;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.menu-title {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  width: 100%;
}

.menu-description {
  text-overflow: ellipsis;
  color: #666;
  font-size: 14px;
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* Slide-down transition */
.slide-enter-active, .slide-leave-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

/* Set height to 90% */
.slidable-content {
  height:100%;
  max-height: 90vh; /* Ensure it respects the viewport height */
  opacity: 1;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

</style>
